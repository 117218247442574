/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CloneTemplateRequest } from '../model/cloneTemplateRequest';
import { CreateTemplateRequest } from '../model/createTemplateRequest';
import { MandatoryComponentsByTemplateTypeRespDto } from '../model/mandatoryComponentsByTemplateTypeRespDto';
import { TemplateMinResponseDto } from '../model/templateMinResponseDto';
import { TemplateResponseDto } from '../model/templateResponseDto';
import { TemplateTypeResponseDto } from '../model/templateTypeResponseDto';
import { UpdateTemplateRequest } from '../model/updateTemplateRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class TemplateControllerService {

    protected basePath = 'https://fidcaresvil.kiranet.it/api/aed';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * cloneForDepartments
     * 
     * @param request request
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling cloneForDepartmentsUsingPOST.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/templates/clone`,
            request,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUsingPOST(request: CreateTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createUsingPOST(request: CreateTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createUsingPOST(request: CreateTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createUsingPOST(request: CreateTemplateRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling createUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/templates/create`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * 
     * @param uuid uuid
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsingDELETE(uuid: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUsingDELETE(uuid: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUsingDELETE(uuid: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUsingDELETE(uuid: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling deleteUsingDELETE.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/templates/delete/${encodeURIComponent(String(uuid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findTemplateByStructureAndTemplateType
     * 
     * @param uuidStructure uuidStructure
     * @param uuidTemplateType uuidTemplateType
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findTemplateByStructureAndTemplateTypeUsingGET(uuidStructure: string, uuidTemplateType: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateResponseDto>;
    public findTemplateByStructureAndTemplateTypeUsingGET(uuidStructure: string, uuidTemplateType: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateResponseDto>>;
    public findTemplateByStructureAndTemplateTypeUsingGET(uuidStructure: string, uuidTemplateType: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateResponseDto>>;
    public findTemplateByStructureAndTemplateTypeUsingGET(uuidStructure: string, uuidTemplateType: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidStructure === null || uuidStructure === undefined) {
            throw new Error('Required parameter uuidStructure was null or undefined when calling findTemplateByStructureAndTemplateTypeUsingGET.');
        }

        if (uuidTemplateType === null || uuidTemplateType === undefined) {
            throw new Error('Required parameter uuidTemplateType was null or undefined when calling findTemplateByStructureAndTemplateTypeUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TemplateResponseDto>(`${this.basePath}/templates/templateByStructureAndType/structure/${encodeURIComponent(String(uuidStructure))}/type/${encodeURIComponent(String(uuidTemplateType))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findTemplateByUuid
     * 
     * @param uuidTemplate uuidTemplate
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findTemplateByUuidUsingGET(uuidTemplate: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateResponseDto>;
    public findTemplateByUuidUsingGET(uuidTemplate: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateResponseDto>>;
    public findTemplateByUuidUsingGET(uuidTemplate: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateResponseDto>>;
    public findTemplateByUuidUsingGET(uuidTemplate: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidTemplate === null || uuidTemplate === undefined) {
            throw new Error('Required parameter uuidTemplate was null or undefined when calling findTemplateByUuidUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TemplateResponseDto>(`${this.basePath}/templates/template/${encodeURIComponent(String(uuidTemplate))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTemplateByStructureAndTemplate
     * 
     * @param uuidStructure uuidStructure
     * @param uuidTemplate uuidTemplate
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateByStructureAndTemplateUsingGET(uuidStructure: string, uuidTemplate: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateResponseDto>;
    public getTemplateByStructureAndTemplateUsingGET(uuidStructure: string, uuidTemplate: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateResponseDto>>;
    public getTemplateByStructureAndTemplateUsingGET(uuidStructure: string, uuidTemplate: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateResponseDto>>;
    public getTemplateByStructureAndTemplateUsingGET(uuidStructure: string, uuidTemplate: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidStructure === null || uuidStructure === undefined) {
            throw new Error('Required parameter uuidStructure was null or undefined when calling getTemplateByStructureAndTemplateUsingGET.');
        }

        if (uuidTemplate === null || uuidTemplate === undefined) {
            throw new Error('Required parameter uuidTemplate was null or undefined when calling getTemplateByStructureAndTemplateUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TemplateResponseDto>(`${this.basePath}/templates/structure/${encodeURIComponent(String(uuidStructure))}/template/${encodeURIComponent(String(uuidTemplate))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTemplateByStructure
     * 
     * @param uuidStructure uuidStructure
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateByStructureUsingGET(uuidStructure: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateMinResponseDto>>;
    public getTemplateByStructureUsingGET(uuidStructure: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateMinResponseDto>>>;
    public getTemplateByStructureUsingGET(uuidStructure: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateMinResponseDto>>>;
    public getTemplateByStructureUsingGET(uuidStructure: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidStructure === null || uuidStructure === undefined) {
            throw new Error('Required parameter uuidStructure was null or undefined when calling getTemplateByStructureUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TemplateMinResponseDto>>(`${this.basePath}/templates/department/${encodeURIComponent(String(uuidStructure))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTemplateTypeByUuid
     * 
     * @param uuidTemplateType uuidTemplateType
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateTypeByUuidUsingGET(uuidTemplateType: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateTypeResponseDto>;
    public getTemplateTypeByUuidUsingGET(uuidTemplateType: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateTypeResponseDto>>;
    public getTemplateTypeByUuidUsingGET(uuidTemplateType: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateTypeResponseDto>>;
    public getTemplateTypeByUuidUsingGET(uuidTemplateType: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidTemplateType === null || uuidTemplateType === undefined) {
            throw new Error('Required parameter uuidTemplateType was null or undefined when calling getTemplateTypeByUuidUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TemplateTypeResponseDto>(`${this.basePath}/templates/templateTypeByUuid/templateType/${encodeURIComponent(String(uuidTemplateType))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTemplateType
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateTypeUsingGET(name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MandatoryComponentsByTemplateTypeRespDto>>;
    public getTemplateTypeUsingGET(name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MandatoryComponentsByTemplateTypeRespDto>>>;
    public getTemplateTypeUsingGET(name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MandatoryComponentsByTemplateTypeRespDto>>>;
    public getTemplateTypeUsingGET(name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MandatoryComponentsByTemplateTypeRespDto>>(`${this.basePath}/templates/templateType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * tryToCloneForDepartments
     * 
     * @param request request
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tryToCloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public tryToCloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public tryToCloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public tryToCloneForDepartmentsUsingPOST(request: CloneTemplateRequest, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling tryToCloneForDepartmentsUsingPOST.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/templates/tryToClone`,
            request,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTemplate
     * 
     * @param request request
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTemplateUsingPUT(request: UpdateTemplateRequest, name?: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateResponseDto>;
    public updateTemplateUsingPUT(request: UpdateTemplateRequest, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateResponseDto>>;
    public updateTemplateUsingPUT(request: UpdateTemplateRequest, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateResponseDto>>;
    public updateTemplateUsingPUT(request: UpdateTemplateRequest, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateTemplateUsingPUT.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TemplateResponseDto>(`${this.basePath}/templates/updateTemplate`,
            request,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
