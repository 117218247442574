export * from './aslDTO';
export * from './aslRegionDTO';
export * from './associationRequest';
export * from './categoryDTO';
export * from './citizenshipDTO';
export * from './cityDTO';
export * from './contactDTO';
export * from './districtDTO';
export * from './experimentationRequest';
export * from './experimentationResponseDTO';
export * from './generalDoctorDTO';
export * from './nationalityDTO';
export * from './neighborhoodDTO';
export * from './otherContactDTO';
export * from './patientAddressDTO';
export * from './patientAddressOutDTO';
export * from './patientAnagDTO';
export * from './patientAnagOutDTO';
export * from './patientDTO';
export * from './patientInsertDTO';
export * from './patientMinDTO';
export * from './provinceDTO';
export * from './referencePeopleDTO';
export * from './referencePeopleInsertDTO';
export * from './studyDTO';
