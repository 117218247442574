/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface OtherContactDTO { 
    /**
     * The addrerss of the contact associated with the patient
     */
    addrerss?: string;
    /**
     * The contact birth date
     */
    birthDate?: Date;
    /**
     * The contact's emails associated with the patient 
     */
    emails?: Array<string>;
    /**
     * The uuid of the contact associated with the patient
     */
    id?: string;
    /**
     * The contact's mobiles associated with the patient 
     */
    mobiles?: Array<string>;
    /**
     * The name of the contact associated with the patient
     */
    name: string;
    /**
     * The contact's phones associated with the patient 
     */
    phones?: Array<string>;
    /**
     * The surname of the contact associated with the patient
     */
    surname: string;
    /**
     * The unique code of the contact associated with the patient
     */
    uniqueCode: string;
}
