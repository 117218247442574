/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PatientAnagDTO { 
    /**
     * The patient's birth city
     */
    birthCityId: string;
    /**
     * The patient's birth date
     */
    birthDate: Date;
    /**
     * The patient's citizenship
     */
    citizenshipId: string;
    /**
     * The patient's name
     */
    name: string;
    /**
     * The patient's sex
     */
    sex: number;
    /**
     * The patient's nationality
     */
    stateNationalityId: string;
    /**
     * The patient's surname
     */
    surname: string;
    /**
     * The patient's typeCode
     */
    typeCode?: string;
    /**
     * The patient's uniqueCode
     */
    uniqueCode: string;
    /**
     * The patient's uuid
     */
    uuid?: string;
}
