/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CheckAicRequest {
  /**
   * Drug's AIC.
   */
  aic: string;
  /**
   * The administrations list
   */
  cs: Array<string>;
  /**
   * The patient's uuid.
   */
  userIdentifier: string;
}
