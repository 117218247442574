import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'infrastructure/fidcare/modules/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesAndDirectiveModule } from 'infrastructure/fidcare/modules/pipes-directives.module';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { TransformationService } from './services/transformation.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		TranslateModule,
		PipesAndDirectiveModule
	],
	declarations: [
		PrintButtonComponent
	],
	exports: [
		PrintButtonComponent
	],
	providers: [
		TransformationService
	]
})
export class ETModule { }
