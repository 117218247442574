/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { PageSurveyResponse } from '../model/pageSurveyResponse';
import { SurveyCreationRequest } from '../model/surveyCreationRequest';
import { SurveyResponse } from '../model/surveyResponse';
import { BASE_PATH } from '../variables';



@Injectable()
export class SurveyService {

  protected basePath = 'https://fidcare.kiranet.it/api/cpmbase';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Add a survey to a Medical Record
   *
   * @param surveyCreationRequest surveyCreationRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(surveyCreationRequest: SurveyCreationRequest, observe?: 'body', reportProgress?: boolean): Observable<SurveyResponse>;
  public create(surveyCreationRequest: SurveyCreationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SurveyResponse>>;
  public create(surveyCreationRequest: SurveyCreationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SurveyResponse>>;
  public create(surveyCreationRequest: SurveyCreationRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (surveyCreationRequest === null || surveyCreationRequest === undefined) {
      throw new Error('Required parameter surveyCreationRequest was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SurveyResponse>(`${this.basePath}/surveys`,
      surveyCreationRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * List Surveys
   * Given a Medical Record UUID, returns all the vital parameter surveys, sorted by date and time, the measured value and the possible triggered alerts
   * @param medicalRecordId medicalRecordId
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list1(medicalRecordId: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageSurveyResponse>;
  public list1(medicalRecordId: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSurveyResponse>>;
  public list1(medicalRecordId: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSurveyResponse>>;
  public list1(medicalRecordId: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (medicalRecordId === null || medicalRecordId === undefined) {
      throw new Error('Required parameter medicalRecordId was null or undefined when calling list1.');
    }



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageSurveyResponse>(`${this.basePath}/surveys/${encodeURIComponent(String(medicalRecordId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
