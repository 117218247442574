/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Location } from './location';
import { ParseState } from './parseState';
import { Throwable } from './throwable';


export interface Problem { 
    location?: Location;
    message?: string;
    parseState?: ParseState;
    resourceDescription?: string;
    rootCause?: Throwable;
}
