// import { SelectListitem } from "../types/model";

// declare global {
//     interface Object {
//         cloneObject<T>(): T
//     }
// }
// Object.prototype.cloneObject = function <T>() {
//     return this == null ? null : <T>(JSON.parse(JSON.stringify(this)));
// }

