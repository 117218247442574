/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AllergiesResponse } from '../model/allergiesResponse';
import { SubmissionDataRequest } from '../model/submissionDataRequest';
import { SubmissionResponse } from '../model/submissionResponse';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class SubmissionControllerService {

    protected basePath = 'https://fidcaresvil.kiranet.it/api/aed';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * compileSubmission
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public compileSubmissionUsingPOST(request: SubmissionDataRequest, observe?: 'body', reportProgress?: boolean): Observable<SubmissionResponse>;
    public compileSubmissionUsingPOST(request: SubmissionDataRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionResponse>>;
    public compileSubmissionUsingPOST(request: SubmissionDataRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionResponse>>;
    public compileSubmissionUsingPOST(request: SubmissionDataRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling compileSubmissionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SubmissionResponse>(`${this.basePath}/submission/compile`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllergiesByPatientUuid
     * 
     * @param uuidPatient uuidPatient
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllergiesByPatientUuidUsingGET(uuidPatient: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<AllergiesResponse>;
    public fetchAllergiesByPatientUuidUsingGET(uuidPatient: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllergiesResponse>>;
    public fetchAllergiesByPatientUuidUsingGET(uuidPatient: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllergiesResponse>>;
    public fetchAllergiesByPatientUuidUsingGET(uuidPatient: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidPatient === null || uuidPatient === undefined) {
            throw new Error('Required parameter uuidPatient was null or undefined when calling fetchAllergiesByPatientUuidUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AllergiesResponse>(`${this.basePath}/submission/allergies/${encodeURIComponent(String(uuidPatient))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getSubmissionByNosologicoAndTemplateTypeUuid
     * 
     * @param nosologico nosologico
     * @param uuidTemplateType uuidTemplateType
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubmissionByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SubmissionResponse>;
    public getSubmissionByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionResponse>>;
    public getSubmissionByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionResponse>>;
    public getSubmissionByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (nosologico === null || nosologico === undefined) {
            throw new Error('Required parameter nosologico was null or undefined when calling getSubmissionByNosologicoAndTemplateTypeUuidUsingGET.');
        }

        if (uuidTemplateType === null || uuidTemplateType === undefined) {
            throw new Error('Required parameter uuidTemplateType was null or undefined when calling getSubmissionByNosologicoAndTemplateTypeUuidUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SubmissionResponse>(`${this.basePath}/submission/nosologico/${encodeURIComponent(String(nosologico))}/templateTypeUuid/${encodeURIComponent(String(uuidTemplateType))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getSubmissionByUuid
     * 
     * @param uuidSubmission uuidSubmission
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubmissionByUuidUsingGET(uuidSubmission: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<SubmissionResponse>;
    public getSubmissionByUuidUsingGET(uuidSubmission: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionResponse>>;
    public getSubmissionByUuidUsingGET(uuidSubmission: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionResponse>>;
    public getSubmissionByUuidUsingGET(uuidSubmission: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (uuidSubmission === null || uuidSubmission === undefined) {
            throw new Error('Required parameter uuidSubmission was null or undefined when calling getSubmissionByUuidUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SubmissionResponse>(`${this.basePath}/submission/find/${encodeURIComponent(String(uuidSubmission))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getSubmissionListByNosologicoAndTemplateTypeUuid
     * 
     * @param nosologico nosologico
     * @param uuidTemplateType uuidTemplateType
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubmissionListByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SubmissionResponse>>;
    public getSubmissionListByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SubmissionResponse>>>;
    public getSubmissionListByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SubmissionResponse>>>;
    public getSubmissionListByNosologicoAndTemplateTypeUuidUsingGET(nosologico: string, uuidTemplateType: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (nosologico === null || nosologico === undefined) {
            throw new Error('Required parameter nosologico was null or undefined when calling getSubmissionListByNosologicoAndTemplateTypeUuidUsingGET.');
        }

        if (uuidTemplateType === null || uuidTemplateType === undefined) {
            throw new Error('Required parameter uuidTemplateType was null or undefined when calling getSubmissionListByNosologicoAndTemplateTypeUuidUsingGET.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SubmissionResponse>>(`${this.basePath}/submission/list/nosologico/${encodeURIComponent(String(nosologico))}/templateTypeUuid/${encodeURIComponent(String(uuidTemplateType))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
