/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GeneralDoctorDTO { 
    /**
     * The code associated to the doctor
     */
    code?: string;
    /**
     * The doctor's creation date
     */
    createDate?: Date;
    /**
     * The create user's uuid
     */
    createUserId?: string;
    /**
     * The doctor's name
     */
    name?: string;
    /**
     * The doctor's surname
     */
    surname?: string;
    /**
     * The doctor's update date
     */
    updateDate?: Date;
    /**
     * The update user's uuid
     */
    updateUserId?: string;
    /**
     * The doctor's uuid
     */
    uuid?: string;
}
