/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { AssociationRequest } from '../model/associationRequest';
import { CategoryDTO } from '../model/categoryDTO';
import { ExperimentationRequest } from '../model/experimentationRequest';
import { ExperimentationResponseDTO } from '../model/experimentationResponseDTO';
import { BASE_PATH } from '../variables';



@Injectable()
export class ExperimentationControllerService {

  protected basePath = 'https://fidcare.kiranet.it/api/cdr';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Associate Experimentation
   *
   * @param request Associate experimentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public associateUsingPOST(request: AssociationRequest, observe?: 'body', reportProgress?: boolean): Observable<ExperimentationResponseDTO>;
  public associateUsingPOST(request: AssociationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExperimentationResponseDTO>>;
  public associateUsingPOST(request: AssociationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExperimentationResponseDTO>>;
  public associateUsingPOST(request: AssociationRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling associateUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ExperimentationResponseDTO>(`${this.basePath}/experimentation/associate`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View a list of categories
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadCategoriesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<CategoryDTO>>;
  public loadCategoriesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CategoryDTO>>>;
  public loadCategoriesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CategoryDTO>>>;
  public loadCategoriesUsingGET(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<CategoryDTO>>(`${this.basePath}/experimentation/categories`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View Experimentation information by id patient
   *
   * @param uuid Id of the patient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public readUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ExperimentationResponseDTO>;
  public readUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExperimentationResponseDTO>>;
  public readUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExperimentationResponseDTO>>;
  public readUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling readUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<ExperimentationResponseDTO>(`${this.basePath}/experimentation/read/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * save
   *
   * @param request Full experimentation&#39;s information
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveUsingPOST(request: ExperimentationRequest, observe?: 'body', reportProgress?: boolean): Observable<ExperimentationResponseDTO>;
  public saveUsingPOST(request: ExperimentationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExperimentationResponseDTO>>;
  public saveUsingPOST(request: ExperimentationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExperimentationResponseDTO>>;
  public saveUsingPOST(request: ExperimentationRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling saveUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ExperimentationResponseDTO>(`${this.basePath}/experimentation/save`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
