/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Patient } from './patient';


export interface TherapyRequest { 
    /**
     * A description for the therapy
     */
    description: string;
    /**
     * The duration in days. Setting to null, 0 or not passing the value means never-ending therapy.
     */
    duration?: number;
    /**
     * Define if the administration order is mandatory
     */
    mandatoryOrder?: boolean;
    medicalRecordUuid?: string;
    /**
     * Additional notes to the therapy
     */
    notes?: string;
    /**
     * A list of pathology codes (if present)
     */
    pathologies?: Array<string>;
    /**
     * Information about the patient
     */
    patient?: Patient;
    /**
     * The protocol unique identifier (if present)
     */
    referenceProtocol?: string;
    /**
     * The start date of the therapy
     */
    startDate: string;
}
