/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SurveyCreationRequest { 
    /**
     * The actual date and time of the measure
     */
    dateTime: Date;
    /**
     * The medical record's ID
     */
    medicalRecordId: string;
    /**
     * The operation ID
     */
    operationId?: string;
    /**
     * A map of key/value pairs with keys(UUID) corresponding to parameterId and values(String) corresponding to measurement value of parameter.
     */
    values: { [key: string]: string; };
}
