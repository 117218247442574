/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DrugDto } from './drugDto';


export interface DrugRequest { 
    /**
     * The administration route.
     */
    administrationRoute?: string;
    /**
     * If true, allow constrain the usage of the explicitly selected commercial drug
     */
    constrainCommercial?: boolean;
    /**
     * The drug.
     */
    drug: DrugDto;
    /**
     * The boolean indicates whether you should bypass the check of allergies
     */
    forceAllergiesComponent: boolean;
    /**
     * A note field
     */
    note?: string;
    /**
     * The administration posology type
     */
    posology: DrugRequest.PosologyEnum;
    /**
     * When a posology value different from DAILY is selected, then the posology param must be added, according to the following rules:  DAYS_OF_WEEK - How many days at week starting from given day. E.g. [3, 2] means every 3 days starting from Monday.  DAYS_OF_MONTH - How many days in a month from given day. E.g. [2, 7] means every 2 days starting from the 7th.  EXACT_DAYS_OF_MONTH - Specifies the exact day(s) of the month. EXACT_DAYS_OF_WEEK - Specifies the exact days of the week. 1 = Sunday.
     */
    posologyParam?: Array<number>;
    /**
     * The quantity of the drug for a single administration
     */
    quantity: number;
    /**
     * The reason of the constrain over the commercial drug. Mandatory when constrainCommercial is true
     */
    reason?: string;
    /**
     * Set if this drug can be replaced
     */
    replaceable?: boolean;
    /**
     * The administration frequency type
     */
    timing: DrugRequest.TimingEnum;
    /**
     * The selected param related to the frequency: EVERY_HOURS - Means every x hours starting from y. E.g. [4, 7] means every 4 hours starting from 7 AM. SPECIFIC_HOUR - Is the list with the hours of the day (0 to 23)
     */
    timingParam?: Array<number>;
}
export namespace DrugRequest {
    export type PosologyEnum = 'DAILY' | 'DAYS_OF_WEEK' | 'DAYS_OF_MONTH' | 'EXACT_DAYS_OF_MONTH' | 'EXACT_DAYS_OF_WEEK';
    export const PosologyEnum = {
        DAILY: 'DAILY' as PosologyEnum,
        DAYSOFWEEK: 'DAYS_OF_WEEK' as PosologyEnum,
        DAYSOFMONTH: 'DAYS_OF_MONTH' as PosologyEnum,
        EXACTDAYSOFMONTH: 'EXACT_DAYS_OF_MONTH' as PosologyEnum,
        EXACTDAYSOFWEEK: 'EXACT_DAYS_OF_WEEK' as PosologyEnum
    };
    export type TimingEnum = 'EVERY_HOURS' | 'SPECIFIC_HOUR';
    export const TimingEnum = {
        EVERYHOURS: 'EVERY_HOURS' as TimingEnum,
        SPECIFICHOUR: 'SPECIFIC_HOUR' as TimingEnum
    };
}
