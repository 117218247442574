/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SubmissionComponentResponse } from './submissionComponentResponse';


export interface SubmissionResponse { 
    creationDate?: Date;
    lastModificationDate?: Date;
    name?: string;
    nosologico?: string;
    schemaData?: string;
    subCompDtoList?: Array<SubmissionComponentResponse>;
    user?: string;
    uuidSubmission?: string;
    uuidTemplate?: string;
}
