import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransformationEntityProvider } from 'app/et-module/model/et.model';
import { TransformationService } from 'app/et-module/services/transformation.service';
import { BaseComponent } from 'infrastructure/fidcare/components/common/base.component';
import { iconDownload } from 'infrastructure/fidcare/utils/icons.utils';

@Component({
    selector: 'fidcare-print-button',
    templateUrl: './print-button.component.html'
})
export class PrintButtonComponent extends BaseComponent implements OnInit {
	@Input() tooltip: string = "common.extract";
	@Input() svgIcon: string = iconDownload;
	@Input() disabled: boolean = false;
	@Input() entityId: string;
	@Input() dataProvider: TransformationEntityProvider;
	@Output() onPrintButtonClick: EventEmitter<any> = new EventEmitter();

	constructor(private _transformationService: TransformationService) {
		super();
	}

	emitClick = ($event) => {
		this.dataProvider.getEntity().subscribe((result) => {
			this._transformationService.transform(this.entityId,result).subscribe((data) => {
				
			})
		})
		// this.onPrintButtonClick.emit($event);
		
	}

	ngOnInit = (): void => {
		// let query: any = {unpaged: true, entity: this.entityId };
		// this._transformationService.list(query).subscribe((result) => {

		// })
	}
}
