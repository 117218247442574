/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CitizenshipDTO } from './citizenshipDTO';
import { CityDTO } from './cityDTO';
import { NationalityDTO } from './nationalityDTO';


export interface PatientAnagOutDTO { 
    /**
     * The patient's city birth
     */
    birthCityId?: CityDTO;
    /**
     * The patient's birth date
     */
    birthDate?: Date;
    /**
     * The patient's citizenship
     */
    citizenshipId?: CitizenshipDTO;
    /**
     * The patient's name
     */
    name?: string;
    /**
     * The patient's sex
     */
    sex?: number;
    /**
     * The patient's nationality
     */
    stateNationalityId?: NationalityDTO;
    /**
     * The patient's surname
     */
    surname?: string;
    /**
     * The patient's typeCode
     */
    typeCode?: string;
    /**
     * The patient's uniqueCode
     */
    uniqueCode?: string;
    /**
     * The patient's uuid
     */
    uuid?: string;
}
