<mat-form-field [floatLabel]="floatLabel" [appearance]="appearance" class="w-full"
    [class.mat-form-field-invalid]="!valid">
    <mat-label>{{(localize ? (label | translate) : label)}}{{(required)?'*':''}}</mat-label>
    <input matInput [id]="id" [(ngModel)]="value"
        placeholder="{{(localize ? (correctPlaceHolder | translate) : (placeHolder || label))}}{{(required)?'*':''}}" [disabled]="disabled"
        decimal [ngModelOptions]="{updateOn: updateOn}" [readonly]="readonly" />
    <span matSuffix>
        <button mat-icon-button *ngIf="helpText != null">
            <mat-icon svgIcon="heroicons_outline:information-circle" (click)="tooltip.toggle()" #tooltip="matTooltip"
                [matTooltip]="helpTextLocalize ? (helpText | translate) : helpText">
            </mat-icon>
        </button>
    </span>
</mat-form-field>
<mat-error *ngIf="!valid">
    {{errorMessage}}
</mat-error>