/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PTComponentDto } from './pTComponentDto';
import { TherapyDto } from './therapyDto';


export interface TherapyDetailsResponse { 
    components?: Array<PTComponentDto>;
    therapyDto?: TherapyDto;
}
