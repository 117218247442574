/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CitizenshipDTO { 
    /**
     * The area code to which the citizenship belongs 
     */
    areaCode?: number;
    /**
     * The area name to which the citizenship belongs 
     */
    areaName?: string;
    /**
     * The citizenship's area type
     */
    areaType?: string;
    /**
     * The citizenship's at code
     */
    atCode?: string;
    /**
     * The citizenship's continent code 1=Europe, 2=Africa, 3=Asia, 4=America, 5=Oceania
     */
    continentCode?: number;
    /**
     * The continent mane to which the citizenship belongs 
     */
    continentName?: string;
    /**
     * The citizenship's creation date
     */
    createDate?: Date;
    /**
     * The create user's uuid
     */
    createUserId?: string;
    /**
     * The citizenship's english name 
     */
    designationEn?: string;
    /**
     * The citizenship's italian name 
     */
    designationIt?: string;
    /**
     * The citizenship's isoCode 2
     */
    isoCode2?: string;
    /**
     * The citizenship's isoCode 3
     */
    isoCode3?: string;
    /**
     * The citizenship's iso parent code 3
     */
    isoParentCode3?: string;
    /**
     * The istat code to which the citizenship belongs 
     */
    istatCode?: string;
    /**
     * The citizenship's istat parent 3
     */
    istatParent?: string;
    /**
     * The citizenship's min code
     */
    minCode?: string;
    /**
     * The citizenship's unsdCode
     */
    unsdCode?: string;
    /**
     * The citizenship's update date
     */
    updateDate?: Date;
    /**
     * The update user's uuid
     */
    updateUserId?: string;
    /**
     * The citizenship's uuid
     */
    uuid?: string;
}
