import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Template } from '../model/template';
import { BASE_PATH } from '../variables';

/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class AgendaTemplateService {

    protected basePath = 'https://fidcaresvil.kiranet.it';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create Agenda Template
     *
     * @param template template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgendaTemplate(template: Template, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public createAgendaTemplate(template: Template, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public createAgendaTemplate(template: Template, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public createAgendaTemplate(template: Template, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (template === null || template === undefined) {
            throw new Error('Required parameter template was null or undefined when calling createAgendaTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Template>(`${this.basePath}/agenda/template`,
            template,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Agenda Template
     *
     * @param templateId templateId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgendaTemplate(templateId: string, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public deleteAgendaTemplate(templateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public deleteAgendaTemplate(templateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public deleteAgendaTemplate(templateId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling deleteAgendaTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Template>(`${this.basePath}/agenda/template/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find All Agenda Templates
     *
     * @param offset
     * @param pageNumber
     * @param pageSize
     * @param paged
     * @param sortSorted
     * @param sortUnsorted
     * @param unpaged
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllAgendaTemplate(offset?: number, pageNumber?: number, pageSize?: number, paged?: boolean, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Template>>;
    public findAllAgendaTemplate(offset?: number, pageNumber?: number, pageSize?: number, paged?: boolean, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Template>>>;
    public findAllAgendaTemplate(offset?: number, pageNumber?: number, pageSize?: number, paged?: boolean, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Template>>>;
    public findAllAgendaTemplate(offset?: number, pageNumber?: number, pageSize?: number, paged?: boolean, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {








        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (paged !== undefined && paged !== null) {
            queryParameters = queryParameters.set('paged', <any>paged);
        }
        if (sortSorted !== undefined && sortSorted !== null) {
            queryParameters = queryParameters.set('sort.sorted', <any>sortSorted);
        }
        if (sortUnsorted !== undefined && sortUnsorted !== null) {
            queryParameters = queryParameters.set('sort.unsorted', <any>sortUnsorted);
        }
        if (unpaged !== undefined && unpaged !== null) {
            queryParameters = queryParameters.set('unpaged', <any>unpaged);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Template>>(`${this.basePath}/agenda/template/templates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find All Agenda Templates
     *
     * @param frontendEnabled
     * @param messageKey
     * @param name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllAgendaTemplateByFilter(frontendEnabled?: boolean, messageKey?: string, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Template>>;
    public findAllAgendaTemplateByFilter(frontendEnabled?: boolean, messageKey?: string, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Template>>>;
    public findAllAgendaTemplateByFilter(frontendEnabled?: boolean, messageKey?: string, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Template>>>;
    public findAllAgendaTemplateByFilter(frontendEnabled?: boolean, messageKey?: string, name?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {




        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (frontendEnabled !== undefined && frontendEnabled !== null) {
            queryParameters = queryParameters.set('frontendEnabled', <any>frontendEnabled);
        }
        if (messageKey !== undefined && messageKey !== null) {
            queryParameters = queryParameters.set('messageKey', <any>messageKey);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Template>>(`${this.basePath}/agenda/template/templates/filter`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find Agenda Template by Id
     *
     * @param templateId templateId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllAgendaTemplateById(templateId: string, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public findAllAgendaTemplateById(templateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public findAllAgendaTemplateById(templateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public findAllAgendaTemplateById(templateId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling findAllAgendaTemplateById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Template>(`${this.basePath}/agenda/template/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Agenda Template
     *
     * @param template template
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgendaTemplate(template: Template, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public updateAgendaTemplate(template: Template, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public updateAgendaTemplate(template: Template, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public updateAgendaTemplate(template: Template, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (template === null || template === undefined) {
            throw new Error('Required parameter template was null or undefined when calling updateAgendaTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Template>(`${this.basePath}/agenda/template`,
            template,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
