import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LayoutComponent {
    constructor() {
    }
}