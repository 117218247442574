/**
 * Vital Measurement API
 * This is a simple API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Sort } from './sort';
import { VitalMeasurementResponseTO } from './vitalMeasurementResponseTO';


export interface PageVitalMeasurementResponseTO { 
    content?: Array<VitalMeasurementResponseTO>;
    empty?: boolean;
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    size?: number;
    sort?: Sort;
    totalElements?: number;
    totalPages?: number;
}
