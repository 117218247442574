/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { ReferencePeopleDTO } from '../model/referencePeopleDTO';
import { ReferencePeopleInsertDTO } from '../model/referencePeopleInsertDTO';
import { BASE_PATH } from '../variables';



@Injectable()
export class ReferencePeopleControllerService {

  protected basePath = 'https://fidcare.kiranet.it/api/cdr';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Create a relation between Patient and Reference people
   *
   * @param patientUuid Patient&#39;s id
   * @param referencePeopleUuid Reference people&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignPatientUsingPUT1(patientUuid: string, referencePeopleUuid: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
  public assignPatientUsingPUT1(patientUuid: string, referencePeopleUuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
  public assignPatientUsingPUT1(patientUuid: string, referencePeopleUuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
  public assignPatientUsingPUT1(patientUuid: string, referencePeopleUuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (patientUuid === null || patientUuid === undefined) {
      throw new Error('Required parameter patientUuid was null or undefined when calling assignPatientUsingPUT1.');
    }

    if (referencePeopleUuid === null || referencePeopleUuid === undefined) {
      throw new Error('Required parameter referencePeopleUuid was null or undefined when calling assignPatientUsingPUT1.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (patientUuid !== undefined && patientUuid !== null) {
      queryParameters = queryParameters.set('patientUuid', <any>patientUuid);
    }
    if (referencePeopleUuid !== undefined && referencePeopleUuid !== null) {
      queryParameters = queryParameters.set('referencePeopleUuid', <any>referencePeopleUuid);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.put<boolean>(`${this.basePath}/referencePeoples`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete existing reference people by id
   *
   * @param uuid Reference people&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteReferencePeopleUsingDELETE(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteReferencePeopleUsingDELETE(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteReferencePeopleUsingDELETE(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteReferencePeopleUsingDELETE(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling deleteReferencePeopleUsingDELETE.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/referencePeoples/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View reference people by id
   *
   * @param uuid Id of the reference people
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getReferencePeopleUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ReferencePeopleDTO>;
  public getReferencePeopleUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferencePeopleDTO>>;
  public getReferencePeopleUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferencePeopleDTO>>;
  public getReferencePeopleUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling getReferencePeopleUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<ReferencePeopleDTO>(`${this.basePath}/referencePeoples/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Save new reference people
   *
   * @param referencePeople Full reference people&#39;s information
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveNewReferencePeopleUsingPOST(referencePeople: ReferencePeopleInsertDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public saveNewReferencePeopleUsingPOST(referencePeople: ReferencePeopleInsertDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public saveNewReferencePeopleUsingPOST(referencePeople: ReferencePeopleInsertDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public saveNewReferencePeopleUsingPOST(referencePeople: ReferencePeopleInsertDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (referencePeople === null || referencePeople === undefined) {
      throw new Error('Required parameter referencePeople was null or undefined when calling saveNewReferencePeopleUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<string>(`${this.basePath}/referencePeoples`,
      referencePeople,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update existing reference people by id
   *
   * @param referencePeople Reference people&#39;s information
   * @param uuid Reference people&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateReferencePeopleUsingPUT(referencePeople: ReferencePeopleInsertDTO, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ReferencePeopleDTO>;
  public updateReferencePeopleUsingPUT(referencePeople: ReferencePeopleInsertDTO, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferencePeopleDTO>>;
  public updateReferencePeopleUsingPUT(referencePeople: ReferencePeopleInsertDTO, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferencePeopleDTO>>;
  public updateReferencePeopleUsingPUT(referencePeople: ReferencePeopleInsertDTO, uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (referencePeople === null || referencePeople === undefined) {
      throw new Error('Required parameter referencePeople was null or undefined when calling updateReferencePeopleUsingPUT.');
    }

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling updateReferencePeopleUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<ReferencePeopleDTO>(`${this.basePath}/referencePeoples/${encodeURIComponent(String(uuid))}`,
      referencePeople,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
