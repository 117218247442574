/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CityDTO } from './cityDTO';


export interface NeighborhoodDTO { 
    /**
     * Not used
     */
    address?: string;
    /**
     * Not used
     */
    cap?: string;
    /**
     * The name of the city to which the neighborhood belongs
     */
    city?: CityDTO;
    /**
     * The neighborhood's code
     */
    code?: string;
    /**
     * The neighborhood's creation date
     */
    createDate?: Date;
    /**
     * The create user's uuid
     */
    createUserId?: string;
    /**
     * The neighborhood's description
     */
    description?: string;
    /**
     * The neighborhood's end date
     */
    endDate?: Date;
    /**
     * Not used
     */
    fax?: string;
    /**
     * Not used
     */
    houseNumber?: string;
    /**
     * Not used
     */
    phone?: string;
    /**
     * The neighborhood's start date
     */
    startDate?: Date;
    /**
     * The neighborhood's update date
     */
    updateDate?: Date;
    /**
     * The update user's uuid
     */
    updateUserId?: string;
    /**
     * The neighborhood's uuid
     */
    uuid?: string;
}
