/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { OtherContactDTO } from '../model/otherContactDTO';
import { PatientAddressDTO } from '../model/patientAddressDTO';
import { PatientAddressOutDTO } from '../model/patientAddressOutDTO';
import { PatientAnagDTO } from '../model/patientAnagDTO';
import { PatientAnagOutDTO } from '../model/patientAnagOutDTO';
import { PatientDTO } from '../model/patientDTO';
import { PatientInsertDTO } from '../model/patientInsertDTO';
import { PatientMinDTO } from '../model/patientMinDTO';
import { BASE_PATH } from '../variables';



@Injectable()
export class PatientControllerService {

  protected basePath = 'https://fidcare.kiranet.it/api/cdr';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Add a contact to a Patient
   *
   * @param id id
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addContact(id: string, request: OtherContactDTO, observe?: 'body', reportProgress?: boolean): Observable<OtherContactDTO>;
  public addContact(id: string, request: OtherContactDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtherContactDTO>>;
  public addContact(id: string, request: OtherContactDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtherContactDTO>>;
  public addContact(id: string, request: OtherContactDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling addContact.');
    }

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling addContact.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<OtherContactDTO>(`${this.basePath}/patients/${encodeURIComponent(String(id))}/addcontact`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new Patient
   *
   * @param patient Full patient&#39;s information
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(patient: PatientInsertDTO, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public create(patient: PatientInsertDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public create(patient: PatientInsertDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public create(patient: PatientInsertDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (patient === null || patient === undefined) {
      throw new Error('Required parameter patient was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<string>(`${this.basePath}/patients`,
      patient,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete existing patient by id
   *
   * @param uuid Patient&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePatientUsingDELETE(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deletePatientUsingDELETE(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deletePatientUsingDELETE(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deletePatientUsingDELETE(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling deletePatientUsingDELETE.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/patients/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View single contact by uniqueCode
   *
   * @param uniqueCode Unique code of the contact
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContactByUniqueCodeUsingGET(uniqueCode: string, observe?: 'body', reportProgress?: boolean): Observable<OtherContactDTO>;
  public getContactByUniqueCodeUsingGET(uniqueCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtherContactDTO>>;
  public getContactByUniqueCodeUsingGET(uniqueCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtherContactDTO>>;
  public getContactByUniqueCodeUsingGET(uniqueCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uniqueCode === null || uniqueCode === undefined) {
      throw new Error('Required parameter uniqueCode was null or undefined when calling getContactByUniqueCodeUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<OtherContactDTO>(`${this.basePath}/patients/contact/${encodeURIComponent(String(uniqueCode))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View patient&#39;s address information by id
   *
   * @param uuid Id of the patient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientAddressUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientAddressOutDTO>;
  public getPatientAddressUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientAddressOutDTO>>;
  public getPatientAddressUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientAddressOutDTO>>;
  public getPatientAddressUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling getPatientAddressUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PatientAddressOutDTO>(`${this.basePath}/patientAddress/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View patient&#39;s anagraphic information by id
   *
   * @param uuid Id of the patient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientAnagUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientAnagOutDTO>;
  public getPatientAnagUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientAnagOutDTO>>;
  public getPatientAnagUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientAnagOutDTO>>;
  public getPatientAnagUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling getPatientAnagUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PatientAnagOutDTO>(`${this.basePath}/patientAnag/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View single patient by uniqueCode
   *
   * @param uniqueCode Unique code of the patient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientByUniqueCodeUsingGET(uniqueCode: string, observe?: 'body', reportProgress?: boolean): Observable<PatientDTO>;
  public getPatientByUniqueCodeUsingGET(uniqueCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientDTO>>;
  public getPatientByUniqueCodeUsingGET(uniqueCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientDTO>>;
  public getPatientByUniqueCodeUsingGET(uniqueCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uniqueCode === null || uniqueCode === undefined) {
      throw new Error('Required parameter uniqueCode was null or undefined when calling getPatientByUniqueCodeUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PatientDTO>(`${this.basePath}/patients/description/${encodeURIComponent(String(uniqueCode))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View basic patient&#39;s information by id
   *
   * @param uuid Id of the patient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientMinUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientMinDTO>;
  public getPatientMinUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientMinDTO>>;
  public getPatientMinUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientMinDTO>>;
  public getPatientMinUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling getPatientMinUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PatientMinDTO>(`${this.basePath}/patientBase/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View single patient by id
   *
   * @param uuid Id of the patient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPatientUsingGET(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientDTO>;
  public getPatientUsingGET(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientDTO>>;
  public getPatientUsingGET(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientDTO>>;
  public getPatientUsingGET(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling getPatientUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PatientDTO>(`${this.basePath}/patients/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * View a page of patients
   *
   * @param pageNo Page number
   * @param pageSize Count of records per page
   * @param sortBy Column&#39;s name for perform ordering
   * @param sortDirection Order type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadPatientsUsingGET(pageNo?: number, pageSize?: number, sortBy?: string, sortDirection?: 'ASC' | 'DESC', observe?: 'body', reportProgress?: boolean): Observable<Array<PatientDTO>>;
  public loadPatientsUsingGET(pageNo?: number, pageSize?: number, sortBy?: string, sortDirection?: 'ASC' | 'DESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientDTO>>>;
  public loadPatientsUsingGET(pageNo?: number, pageSize?: number, sortBy?: string, sortDirection?: 'ASC' | 'DESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientDTO>>>;
  public loadPatientsUsingGET(pageNo?: number, pageSize?: number, sortBy?: string, sortDirection?: 'ASC' | 'DESC', observe: any = 'body', reportProgress: boolean = false): Observable<any> {





    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (pageNo !== undefined && pageNo !== null) {
      queryParameters = queryParameters.set('pageNo', <any>pageNo);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (sortDirection !== undefined && sortDirection !== null) {
      queryParameters = queryParameters.set('sortDirection', <any>sortDirection);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<PatientDTO>>(`${this.basePath}/patients`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete contact person
   *
   * @param uuidContact uuidContact
   * @param uuidPatient uuidPatient
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeContact(uuidContact: string, uuidPatient: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public removeContact(uuidContact: string, uuidPatient: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public removeContact(uuidContact: string, uuidPatient: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public removeContact(uuidContact: string, uuidPatient: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuidContact === null || uuidContact === undefined) {
      throw new Error('Required parameter uuidContact was null or undefined when calling removeContact.');
    }

    if (uuidPatient === null || uuidPatient === undefined) {
      throw new Error('Required parameter uuidPatient was null or undefined when calling removeContact.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/patients/${encodeURIComponent(String(uuidPatient))}/removecontact/${encodeURIComponent(String(uuidContact))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update contact info
   *
   * @param id id
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateContact(id: string, request: OtherContactDTO, observe?: 'body', reportProgress?: boolean): Observable<OtherContactDTO>;
  public updateContact(id: string, request: OtherContactDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtherContactDTO>>;
  public updateContact(id: string, request: OtherContactDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtherContactDTO>>;
  public updateContact(id: string, request: OtherContactDTO, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateContact.');
    }

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling updateContact.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<OtherContactDTO>(`${this.basePath}/patients/${encodeURIComponent(String(id))}/updatecontact`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update patient&#39;s address by id
   *
   * @param patient Patient&#39;s information
   * @param uuid Patient&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePatientAddressUsingPUT(patient: PatientAddressDTO, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientAddressDTO>;
  public updatePatientAddressUsingPUT(patient: PatientAddressDTO, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientAddressDTO>>;
  public updatePatientAddressUsingPUT(patient: PatientAddressDTO, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientAddressDTO>>;
  public updatePatientAddressUsingPUT(patient: PatientAddressDTO, uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (patient === null || patient === undefined) {
      throw new Error('Required parameter patient was null or undefined when calling updatePatientAddressUsingPUT.');
    }

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling updatePatientAddressUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<PatientAddressDTO>(`${this.basePath}/patientAddress/${encodeURIComponent(String(uuid))}`,
      patient,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update patient&#39;s anagraphic information by id
   *
   * @param patient Patient&#39;s information
   * @param uuid Patient&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePatientAnagUsingPUT(patient: PatientAnagDTO, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientAnagDTO>;
  public updatePatientAnagUsingPUT(patient: PatientAnagDTO, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientAnagDTO>>;
  public updatePatientAnagUsingPUT(patient: PatientAnagDTO, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientAnagDTO>>;
  public updatePatientAnagUsingPUT(patient: PatientAnagDTO, uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (patient === null || patient === undefined) {
      throw new Error('Required parameter patient was null or undefined when calling updatePatientAnagUsingPUT.');
    }

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling updatePatientAnagUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<PatientAnagDTO>(`${this.basePath}/patientAnag/${encodeURIComponent(String(uuid))}`,
      patient,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update existing patient by id
   *
   * @param patient Patient&#39;s information
   * @param uuid Patient&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePatientUsingPUT(patient: PatientInsertDTO, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PatientInsertDTO>;
  public updatePatientUsingPUT(patient: PatientInsertDTO, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PatientInsertDTO>>;
  public updatePatientUsingPUT(patient: PatientInsertDTO, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PatientInsertDTO>>;
  public updatePatientUsingPUT(patient: PatientInsertDTO, uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (patient === null || patient === undefined) {
      throw new Error('Required parameter patient was null or undefined when calling updatePatientUsingPUT.');
    }

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling updatePatientUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<PatientInsertDTO>(`${this.basePath}/patients/${encodeURIComponent(String(uuid))}`,
      patient,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
