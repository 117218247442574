/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DrugRequest } from '../model/drugRequest';
import { PageTherapyDto } from '../model/pageTherapyDto';
import { Problem } from '../model/problem';
import { SutDto } from '../model/sutDto';
import { TherapyDetailsResponse } from '../model/therapyDetailsResponse';
import { TherapyRequest } from '../model/therapyRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class TherapyService {

    protected basePath = 'https://fidcaresvil.kiranet.it/api/atm';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activate
     * activate a therapy given a medical record id
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activate(tid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activate(tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activate(tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activate(tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling activate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addComponent
     * Add a component to an existing therapy given a the id
     * @param request request
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addComponent(request: DrugRequest, tid: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public addComponent(request: DrugRequest, tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public addComponent(request: DrugRequest, tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public addComponent(request: DrugRequest, tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling addComponent.');
        }

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling addComponent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/component`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTherapy
     * Create a new therapy given a medical record id
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTherapy(request: TherapyRequest, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public createTherapy(request: TherapyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public createTherapy(request: TherapyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public createTherapy(request: TherapyRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling createTherapy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/therapies`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cristian
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cristianUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cristianUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cristianUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cristianUsingGET(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/therapies/crs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteComponent
     * Remove a component from an existing therapy given a the therapy id and the component id
     * @param componentId componentId
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteComponent(componentId: string, tid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteComponent(componentId: string, tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteComponent(componentId: string, tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteComponent(componentId: string, tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (componentId === null || componentId === undefined) {
            throw new Error('Required parameter componentId was null or undefined when calling deleteComponent.');
        }

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling deleteComponent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/deleteComponent/${encodeURIComponent(String(componentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteTherapy
     * Delete a therapy given a the therapy id. The therapy can be to delete if its is in SAVED state
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTherapy(tid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTherapy(tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTherapy(tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTherapy(tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling deleteTherapy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/therapies/deleteTherapy/${encodeURIComponent(String(tid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findCurrentByFilter
     * 
     * @param medicalRecordId medicalRecordId
     * @param description 
     * @param page page
     * @param pathology 
     * @param size size
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCurrentByFilterUsingGET(medicalRecordId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe?: 'body', reportProgress?: boolean): Observable<PageTherapyDto>;
    public findCurrentByFilterUsingGET(medicalRecordId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTherapyDto>>;
    public findCurrentByFilterUsingGET(medicalRecordId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTherapyDto>>;
    public findCurrentByFilterUsingGET(medicalRecordId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (medicalRecordId === null || medicalRecordId === undefined) {
            throw new Error('Required parameter medicalRecordId was null or undefined when calling findCurrentByFilterUsingGET.');
        }






        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pathology !== undefined && pathology !== null) {
            queryParameters = queryParameters.set('pathology', <any>pathology);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageTherapyDto>(`${this.basePath}/therapies/filter/${encodeURIComponent(String(medicalRecordId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findHistoricalByFilter
     * 
     * @param patientId patientId
     * @param description 
     * @param page page
     * @param pathology 
     * @param size size
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findHistoricalByFilterUsingGET(patientId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe?: 'body', reportProgress?: boolean): Observable<PageTherapyDto>;
    public findHistoricalByFilterUsingGET(patientId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTherapyDto>>;
    public findHistoricalByFilterUsingGET(patientId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTherapyDto>>;
    public findHistoricalByFilterUsingGET(patientId: string, description?: string, page?: number, pathology?: string, size?: number, status?: 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling findHistoricalByFilterUsingGET.');
        }






        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pathology !== undefined && pathology !== null) {
            queryParameters = queryParameters.set('pathology', <any>pathology);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageTherapyDto>(`${this.basePath}/therapies/historicalfilter/${encodeURIComponent(String(patientId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getSut
     * Get the unique therapy report (SUT)
     * @param from from
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSut(from: number, tid: string, observe?: 'body', reportProgress?: boolean): Observable<SutDto>;
    public getSut(from: number, tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SutDto>>;
    public getSut(from: number, tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SutDto>>;
    public getSut(from: number, tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getSut.');
        }

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling getSut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SutDto>(`${this.basePath}/therapies/sut/${encodeURIComponent(String(tid))}/${encodeURIComponent(String(from))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTherapies
     * List all the therapies given a medical record id
     * @param medicalRecordId medicalRecordId
     * @param description description
     * @param page page
     * @param size size
     * @param state state
     * @param updatedBy updatedBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTherapies(medicalRecordId: string, description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe?: 'body', reportProgress?: boolean): Observable<PageTherapyDto>;
    public getTherapies(medicalRecordId: string, description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTherapyDto>>;
    public getTherapies(medicalRecordId: string, description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTherapyDto>>;
    public getTherapies(medicalRecordId: string, description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (medicalRecordId === null || medicalRecordId === undefined) {
            throw new Error('Required parameter medicalRecordId was null or undefined when calling getTherapies.');
        }






        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (updatedBy !== undefined && updatedBy !== null) {
            queryParameters = queryParameters.set('updatedBy', <any>updatedBy);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageTherapyDto>(`${this.basePath}/therapies/${encodeURIComponent(String(medicalRecordId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTherapiesByDoctor
     * List all the therapies given a doctor id
     * @param description description
     * @param page page
     * @param size size
     * @param state state
     * @param updatedBy updatedBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTherapiesByDoctor(description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe?: 'body', reportProgress?: boolean): Observable<PageTherapyDto>;
    public getTherapiesByDoctor(description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTherapyDto>>;
    public getTherapiesByDoctor(description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTherapyDto>>;
    public getTherapiesByDoctor(description?: string, page?: number, size?: number, state?: string, updatedBy?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {






        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (updatedBy !== undefined && updatedBy !== null) {
            queryParameters = queryParameters.set('updatedBy', <any>updatedBy);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageTherapyDto>(`${this.basePath}/therapies/bydoctor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTherapyDetails
     * Get therapy details from the id
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTherapyDetails(tid: string, observe?: 'body', reportProgress?: boolean): Observable<TherapyDetailsResponse>;
    public getTherapyDetails(tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TherapyDetailsResponse>>;
    public getTherapyDetails(tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TherapyDetailsResponse>>;
    public getTherapyDetails(tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling getTherapyDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TherapyDetailsResponse>(`${this.basePath}/therapies/details/${encodeURIComponent(String(tid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * interrupt
     * interrupt a therapy given a medical record id
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public interrupt(tid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public interrupt(tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public interrupt(tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public interrupt(tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling interrupt.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/interrupt`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * restoreTherapy
     * Restore a therapy in state INTERRUPTED or TERMINATED given a therapy tid and medical_record id
     * @param medicalRecordId medicalRecordId
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreTherapy(medicalRecordId: string, tid: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public restoreTherapy(medicalRecordId: string, tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public restoreTherapy(medicalRecordId: string, tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public restoreTherapy(medicalRecordId: string, tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (medicalRecordId === null || medicalRecordId === undefined) {
            throw new Error('Required parameter medicalRecordId was null or undefined when calling restoreTherapy.');
        }

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling restoreTherapy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/restoreTherapy/${encodeURIComponent(String(medicalRecordId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * start
     * Start a therapy given a medical record id
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public start(tid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public start(tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public start(tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public start(tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling start.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/start`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * terminate
     * terminate a therapy given a medical record id
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public terminate(tid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public terminate(tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public terminate(tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public terminate(tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling terminate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/terminate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * test
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testUsingGET(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public testUsingGET(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public testUsingGET(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public testUsingGET(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling testUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/therapies/test/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateComponent
     * Update a component from an existing therapy given a the therapy id and the component id
     * @param componentId componentId
     * @param request request
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateComponent(componentId: string, request: DrugRequest, tid: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public updateComponent(componentId: string, request: DrugRequest, tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public updateComponent(componentId: string, request: DrugRequest, tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public updateComponent(componentId: string, request: DrugRequest, tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (componentId === null || componentId === undefined) {
            throw new Error('Required parameter componentId was null or undefined when calling updateComponent.');
        }

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateComponent.');
        }

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling updateComponent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/updateComponent/${encodeURIComponent(String(componentId))}`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTherapy
     * Update an existing therapy given its id
     * @param request request
     * @param tid tid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTherapy(request: TherapyRequest, tid: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public updateTherapy(request: TherapyRequest, tid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public updateTherapy(request: TherapyRequest, tid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public updateTherapy(request: TherapyRequest, tid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateTherapy.');
        }

        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling updateTherapy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/therapies/${encodeURIComponent(String(tid))}/update`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
