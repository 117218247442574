/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { DistrictDto } from '../model/districtDto';
import { DistrictRequest } from '../model/districtRequest';
import { DistrictResponse } from '../model/districtResponse';
import { PageDistrictResponse } from '../model/pageDistrictResponse';
import { BASE_PATH } from '../variables';



@Injectable()
export class DistrictService {

  protected basePath = 'https://fidcaresvil.kiranet.it';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Create a new District
   *
   * @param districtRequest districtRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(districtRequest: DistrictRequest, observe?: 'body', reportProgress?: boolean): Observable<DistrictDto>;
  public create(districtRequest: DistrictRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistrictDto>>;
  public create(districtRequest: DistrictRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistrictDto>>;
  public create(districtRequest: DistrictRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (districtRequest === null || districtRequest === undefined) {
      throw new Error('Required parameter districtRequest was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DistrictDto>(`${this.basePath}/districts/saveDistrict`,
      districtRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the paged list of the districts
   *
   * @param districtAsl districtAsl
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllByFilter(districtAsl?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageDistrictResponse>;
  public findAllByFilter(districtAsl?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDistrictResponse>>;
  public findAllByFilter(districtAsl?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDistrictResponse>>;
  public findAllByFilter(districtAsl?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {




    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (districtAsl !== undefined && districtAsl !== null) {
      queryParameters = queryParameters.set('districtAsl', <any>districtAsl);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDistrictResponse>(`${this.basePath}/districts/getDistrictList`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of all the districts
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllDistricts(observe?: 'body', reportProgress?: boolean): Observable<Array<DistrictResponse>>;
  public findAllDistricts(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DistrictResponse>>>;
  public findAllDistricts(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DistrictResponse>>>;
  public findAllDistricts(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<DistrictResponse>>(`${this.basePath}/districts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get district by USCA operator
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDistrictByUscaOperator(id: string, observe?: 'body', reportProgress?: boolean): Observable<DistrictResponse>;
  public findDistrictByUscaOperator(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistrictResponse>>;
  public findDistrictByUscaOperator(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistrictResponse>>;
  public findDistrictByUscaOperator(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling findDistrictByUscaOperator.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<DistrictResponse>(`${this.basePath}/districts/usca/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update an existing District
   *
   * @param districtRequest districtRequest
   * @param uuid uuid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public update(districtRequest: DistrictRequest, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<DistrictDto>;
  public update(districtRequest: DistrictRequest, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistrictDto>>;
  public update(districtRequest: DistrictRequest, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistrictDto>>;
  public update(districtRequest: DistrictRequest, uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (districtRequest === null || districtRequest === undefined) {
      throw new Error('Required parameter districtRequest was null or undefined when calling update.');
    }

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling update.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DistrictDto>(`${this.basePath}/districts/${encodeURIComponent(String(uuid))}`,
      districtRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
