/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdministrationAggregateDto } from './administrationAggregateDto';
import { AllergyDto } from './allergyDto';


export interface SutDto { 
    activatedBy?: string;
    activationDate?: string;
    administrations?: Array<AdministrationAggregateDto>;
    allergies?: Array<AllergyDto>;
    description?: string;
    duration?: number;
    endDate?: string;
    interruptedBy?: string;
    interruptionDate?: string;
    isChronical?: boolean;
    notes?: string;
    pathologies?: Array<string>;
    startDate?: string;
}
