/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PathologyDto } from './pathologyDto';


export interface TherapyDto { 
    /**
     * The therapy description
     */
    description?: string;
    /**
     * The duration in days. Setting to null, 0 or not passing the value means never-ending therapy.
     */
    duration?: number;
    /**
     * If the therapy is interrupted, this field reports the person who interrupted
     */
    interruptedBy?: string;
    /**
     * If the therapy is interrupted, this field reports the interruption date time
     */
    interruptedDate?: Date;
    isChronical?: boolean;
    /**
     * The local date time of the last update
     */
    lastUpdate?: Date;
    /**
     * Define if the administration order is mandatory
     */
    mandatoryOrder?: boolean;
    /**
     * Additional notes to the therapy
     */
    notes?: string;
    /**
     * Zero or more pahtologies linked to the therapy
     */
    pathologies?: Array<PathologyDto>;
    /**
     * The start date of the therapy
     */
    startDate: string;
    /**
     * The therapy state. Can be one of ACTIVE, STARTED, INTERRUPTED, TERMINATED
     */
    state?: string;
    /**
     * The name of the doctor that updated last
     */
    updatedBy?: string;
    /**
     * The therapy identifier
     */
    uuid?: string;
}
