/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PatientMinDTO } from './patientMinDTO';


export interface ReferencePeopleDTO { 
    cell?: string;
    createDate?: Date;
    createUserId?: string;
    label?: string;
    mail?: string;
    name?: string;
    order?: number;
    patientMinDTO?: PatientMinDTO;
    phone?: string;
    secondPhone?: string;
    surname?: string;
    updateDate?: Date;
    updateUserId?: string;
    uuid?: string;
}
