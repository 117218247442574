/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Locale } from './locale';
import { StatusType } from './statusType';


export interface ErrorMessageModel { 
    errorCode?: string;
    errorMessage?: string;
    errorStatus?: StatusType;
    errorTitle?: string;
    listParameters?: Array<any>;
    locale?: Locale;
}
