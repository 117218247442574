export * from './aslController.service';
import { AslControllerService } from './aslController.service';
export * from './aslRegionController.service';
import { AslRegionControllerService } from './aslRegionController.service';
export * from './citizenshipController.service';
import { CitizenshipControllerService } from './citizenshipController.service';
export * from './cityController.service';
import { CityControllerService } from './cityController.service';
export * from './districtController.service';
import { DistrictControllerService } from './districtController.service';
export * from './experimentationController.service';
import { ExperimentationControllerService } from './experimentationController.service';
export * from './generalDoctorController.service';
import { GeneralDoctorControllerService } from './generalDoctorController.service';
export * from './nationalityController.service';
import { NationalityControllerService } from './nationalityController.service';
export * from './neighborhoodController.service';
import { NeighborhoodControllerService } from './neighborhoodController.service';
export * from './patientController.service';
import { PatientControllerService } from './patientController.service';
export * from './provinceController.service';
import { ProvinceControllerService } from './provinceController.service';
export * from './referencePeopleController.service';
import { ReferencePeopleControllerService } from './referencePeopleController.service';
export * from './studyController.service';
import { StudyControllerService } from './studyController.service';
export const APIS = [AslControllerService, AslRegionControllerService, CitizenshipControllerService, CityControllerService, DistrictControllerService, ExperimentationControllerService, GeneralDoctorControllerService, NationalityControllerService, NeighborhoodControllerService, PatientControllerService, ProvinceControllerService, ReferencePeopleControllerService, StudyControllerService];
