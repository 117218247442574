/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasureUnitValue } from './measureUnitValue';


export interface ParameterResponse { 
    description?: string;
    maxValue?: number;
    measureUnit?: MeasureUnitValue;
    minValue?: number;
    primaryParameter?: boolean;
    uuid?: string;
    values?: Array<string>;
}
