/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalTime } from './localTime';


export interface AdministrationDto { 
    actualDateAdministration?: string;
    actualTimeAdministration?: LocalTime;
    id?: string;
    state?: AdministrationDto.StateEnum;
    notes?: string;
}
export namespace AdministrationDto {
    export type StateEnum = 'TO_PREPARE' | 'TO_ADMINISTER' | 'NOT_ADMINISTERED_MISSED' | 'NOT_ADMINISTERED_REJECTED' | 'NOT_ADMINISTERED_TIME_WINDOW_EXPIRED' | 'UNKNOWN' | 'ADMINISTERED';
    export const StateEnum = {
        TOPREPARE: 'TO_PREPARE' as StateEnum,
        TOADMINISTER: 'TO_ADMINISTER' as StateEnum,
        NOTADMINISTEREDMISSED: 'NOT_ADMINISTERED_MISSED' as StateEnum,
        NOTADMINISTEREDREJECTED: 'NOT_ADMINISTERED_REJECTED' as StateEnum,
        NOTADMINISTEREDTIMEWINDOWEXPIRED: 'NOT_ADMINISTERED_TIME_WINDOW_EXPIRED' as StateEnum,
        UNKNOWN: 'UNKNOWN' as StateEnum,
        ADMINISTERED: 'ADMINISTERED' as StateEnum
    };
}
