/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ComponentResponseDto { 
    /**
     * The component's consumer
     */
    consumer: string;
    /**
     * The component's datatype
     */
    dataType: ComponentResponseDto.DataTypeEnum;
    /**
     * If datatype is LIST allow the multiselection or not
     */
    multiselect: boolean;
    /**
     * The component's name
     */
    name: string;
    /**
     * The component's sda
     */
    sda: boolean;
    /**
     * The component's sdo
     */
    sdo: boolean;
    /**
     * The component's source
     */
    source: string;
    /**
     * The component's uuid
     */
    uuid: string;
}
export namespace ComponentResponseDto {
    export type DataTypeEnum = 'NUMBER' | 'LIST' | 'TEXT';
    export const DataTypeEnum = {
        NUMBER: 'NUMBER' as DataTypeEnum,
        LIST: 'LIST' as DataTypeEnum,
        TEXT: 'TEXT' as DataTypeEnum
    };
}
