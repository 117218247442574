/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DistrictDTO { 
    /**
     * The district's address
     */
    address?: string;
    /**
     * The district's description
     */
    description?: string;
    id?: number;
    /**
     * The district's name
     */
    name?: string;
    /**
     * The district's phone number
     */
    phone?: string;
    /**
     * The district's uuid
     */
    uuid?: string;
}
