<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" mode="over" [name]="'mainNavigation'"
    [navigation]="navigationItems" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-16 bg-gray-200">
            <!-- Dark version -->
            <img class="w-30" src="assets/images/logo/logo.png" alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex items-center w-full h-16 z-49">
        <div class="bg-transparent flex items-center px-4 md:px-6 ">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <img class="w-36" src="assets/images/logo/logo.png" alt="Logo image">
        </div>
        <div class="bg-primary h-16 px-6 md:px-8 flex items-center text-on-primary rounded-bl-2xl text-3xl">
            {{title | translate}}
        </div>
        <div class="bg-primary relative flex flex-1 justify-end items-center h-16 px-4 md:px-6">
            <!-- Components -->
            <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                <div>
                    <button mat-icon-button routerLink="/age/agenda" class="bg-card mr-1">
                        <mat-icon svgIcon="heroicons_outline:calendar" class="text-primary"></mat-icon>
                    </button>
                </div>
                <notifications class="block"></notifications>
                <fuse-fullscreen class="hidden md:block" tooltip="{{'common.fullScreenButtonLabel' | translate}}"></fuse-fullscreen>
				<button mat-icon-button routerLink="/" class="bg-card mr-1" [matTooltip]="'common.homeButtonLabel' | translate">
					<mat-icon svgIcon="apps" class="text-primary"></mat-icon>
				</button>
                <user></user>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <div cdkscrollable="" class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto">
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Kiranet &copy; {{currentYear}}</span>
    </div>

</div>