/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationMeasurementResponse } from './operationMeasurementResponse';
import { ParameterResponse } from './parameterResponse';


export interface SurveyResponse {
  dateTime?: Date;
  operation?: OperationMeasurementResponse;
  parameter?: Array<ParameterResponse>;
  uuid?: string;
}
