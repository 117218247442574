/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { MedicalRecordRequest } from '../model/medicalRecordRequest';
import { PageMedicalRecordRequest } from '../model/pageMedicalRecordRequest';
import { BASE_PATH } from '../variables';



@Injectable()
export class MedicalRecordService {

  protected basePath = 'https://fidcaresvil.kiranet.it/api/mrc';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Create a new medical record
   *
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(request: MedicalRecordRequest, observe?: 'body', reportProgress?: boolean): Observable<MedicalRecordRequest>;
  public create(request: MedicalRecordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedicalRecordRequest>>;
  public create(request: MedicalRecordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedicalRecordRequest>>;
  public create(request: MedicalRecordRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<MedicalRecordRequest>(`${this.basePath}/medicalrecords`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records by SUPERUSCA
   *
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/superusca`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records filtered by the district of the operator
   *
   * @param districtCode District&#39;s code of the operator
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list1(districtCode: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list1(districtCode: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list1(districtCode: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list1(districtCode: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (districtCode === null || districtCode === undefined) {
      throw new Error('Required parameter districtCode was null or undefined when calling list1.');
    }



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/district/${encodeURIComponent(String(districtCode))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records associated to district filtered by multiple params
   *
   * @param districtCode District&#39;s code of the operator
   * @param code
   * @param endDate
   * @param nosological
   * @param page page
   * @param patientName
   * @param patientSurname
   * @param size size
   * @param startDate
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list2(districtCode: string, code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list2(districtCode: string, code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list2(districtCode: string, code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list2(districtCode: string, code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (districtCode === null || districtCode === undefined) {
      throw new Error('Required parameter districtCode was null or undefined when calling list2.');
    }










    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (endDate !== undefined && endDate !== null) {
      queryParameters = queryParameters.set('endDate', <any>endDate);
    }
    if (nosological !== undefined && nosological !== null) {
      queryParameters = queryParameters.set('nosological', <any>nosological);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (patientName !== undefined && patientName !== null) {
      queryParameters = queryParameters.set('patientName', <any>patientName);
    }
    if (patientSurname !== undefined && patientSurname !== null) {
      queryParameters = queryParameters.set('patientSurname', <any>patientSurname);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = queryParameters.set('startDate', <any>startDate);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/district/${encodeURIComponent(String(districtCode))}/filter`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records associated to doctor filtered by multiple params
   *
   * @param code
   * @param endDate
   * @param nosological
   * @param page page
   * @param patientName
   * @param patientSurname
   * @param size size
   * @param startDate
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list3(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list3(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list3(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list3(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {










    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (endDate !== undefined && endDate !== null) {
      queryParameters = queryParameters.set('endDate', <any>endDate);
    }
    if (nosological !== undefined && nosological !== null) {
      queryParameters = queryParameters.set('nosological', <any>nosological);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (patientName !== undefined && patientName !== null) {
      queryParameters = queryParameters.set('patientName', <any>patientName);
    }
    if (patientSurname !== undefined && patientSurname !== null) {
      queryParameters = queryParameters.set('patientSurname', <any>patientSurname);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = queryParameters.set('startDate', <any>startDate);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/doctor/filter`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records filtered by doctor id
   *
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list4(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list4(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list4(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list4(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/doctor`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records filtered by USCA id
   *
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list5(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list5(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list5(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list5(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/usca`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records filtered by patient id
   *
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list6(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list6(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list6(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list6(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/patient`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records filtered by multiple params
   *
   * @param code
   * @param endDate
   * @param nosological
   * @param page page
   * @param patientName
   * @param patientSurname
   * @param size size
   * @param startDate
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list7(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list7(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list7(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list7(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {










    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (endDate !== undefined && endDate !== null) {
      queryParameters = queryParameters.set('endDate', <any>endDate);
    }
    if (nosological !== undefined && nosological !== null) {
      queryParameters = queryParameters.set('nosological', <any>nosological);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (patientName !== undefined && patientName !== null) {
      queryParameters = queryParameters.set('patientName', <any>patientName);
    }
    if (patientSurname !== undefined && patientSurname !== null) {
      queryParameters = queryParameters.set('patientSurname', <any>patientSurname);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = queryParameters.set('startDate', <any>startDate);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/superusca/filter`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records associated to usca&#39;s district filtered by multiple params
   *
   * @param code
   * @param endDate
   * @param nosological
   * @param page page
   * @param patientName
   * @param patientSurname
   * @param size size
   * @param startDate
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list8(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
  public list8(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
  public list8(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
  public list8(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {










    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (endDate !== undefined && endDate !== null) {
      queryParameters = queryParameters.set('endDate', <any>endDate);
    }
    if (nosological !== undefined && nosological !== null) {
      queryParameters = queryParameters.set('nosological', <any>nosological);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (patientName !== undefined && patientName !== null) {
      queryParameters = queryParameters.set('patientName', <any>patientName);
    }
    if (patientSurname !== undefined && patientSurname !== null) {
      queryParameters = queryParameters.set('patientSurname', <any>patientSurname);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = queryParameters.set('startDate', <any>startDate);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords/usca/filter`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the details of the medical record given the ID
   *
   * @param id MedicalRecord&#39;s id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list9(id: string, observe?: 'body', reportProgress?: boolean): Observable<MedicalRecordRequest>;
  public list9(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedicalRecordRequest>>;
  public list9(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedicalRecordRequest>>;
  public list9(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling list9.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<MedicalRecordRequest>(`${this.basePath}/medicalrecords/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update Medical Record status
   *
   * @param id id
   * @param status Status must be CHIUSA
   * @param note Closing reason
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public update(id: string, status: string, note?: string, observe?: 'body', reportProgress?: boolean): Observable<MedicalRecordRequest>;
  public update(id: string, status: string, note?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedicalRecordRequest>>;
  public update(id: string, status: string, note?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedicalRecordRequest>>;
  public update(id: string, status: string, note?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling update.');
    }

    if (status === null || status === undefined) {
      throw new Error('Required parameter status was null or undefined when calling update.');
    }


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<MedicalRecordRequest>(`${this.basePath}/medicalrecords/${encodeURIComponent(String(id))}/${encodeURIComponent(String(status))}`,
      note,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the medical records
   *
   * @param code
   * @param endDate
   * @param nosological
   * @param page page
   * @param patientName
   * @param patientSurname
   * @param size size
   * @param startDate
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public listMrc(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMedicalRecordRequest>;
   public listMrc(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMedicalRecordRequest>>;
   public listMrc(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMedicalRecordRequest>>;
   public listMrc(code?: string, endDate?: string, nosological?: string, page?: number, patientName?: string, patientSurname?: string, size?: number, startDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
 
     let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
     if (code !== undefined && code !== null) {
       queryParameters = queryParameters.set('code', <any>code);
     }
     if (endDate !== undefined && endDate !== null) {
       queryParameters = queryParameters.set('endDate', <any>endDate);
     }
     if (nosological !== undefined && nosological !== null) {
       queryParameters = queryParameters.set('nosological', <any>nosological);
     }
     if (page !== undefined && page !== null) {
       queryParameters = queryParameters.set('page', <any>page);
     }
     if (patientName !== undefined && patientName !== null) {
       queryParameters = queryParameters.set('patientName', <any>patientName);
     }
     if (patientSurname !== undefined && patientSurname !== null) {
       queryParameters = queryParameters.set('patientSurname', <any>patientSurname);
     }
     if (size !== undefined && size !== null) {
       queryParameters = queryParameters.set('size', <any>size);
     }
     if (startDate !== undefined && startDate !== null) {
       queryParameters = queryParameters.set('startDate', <any>startDate);
     }
     if (status !== undefined && status !== null) {
       queryParameters = queryParameters.set('status', <any>status);
     }
 
     let headers = this.defaultHeaders;
 
     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
       '*/*'
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
       headers = headers.set('Accept', httpHeaderAcceptSelected);
     }
 
     // to determine the Content-Type header
     const consumes: string[] = [
     ];
 
     return this.httpClient.get<PageMedicalRecordRequest>(`${this.basePath}/medicalrecords`,
       {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers: headers,
         observe: observe,
         reportProgress: reportProgress
       }
     );
   }

}
