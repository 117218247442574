/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateAdministrationRequest {
  /**
   * The administrations list uuids.
   */
  cs: Array<string>;
  /**
   * The action time stamp.
   */
  messageTimestamp: number;
  /**
   * The new state to associate to administartions. Possible value: TO_PREPARE, TO_ADMINISTER, NOT_ADMINISTERED_MISSED, NOT_ADMINISTERED_REJECTED, NOT_ADMINISTERED_TIME_WINDOW_EXPIRED, UNKNOWN, ADMINISTERED
   */
  newCsState: UpdateAdministrationRequest.NewCsStateEnum;
  /**
   * The patient's uuid.
   */
  userIdentifier: string;
}
export namespace UpdateAdministrationRequest {
  export type NewCsStateEnum = 'TO_PREPARE' | 'TO_ADMINISTER' | 'NOT_ADMINISTERED_MISSED' | 'NOT_ADMINISTERED_REJECTED' | 'NOT_ADMINISTERED_TIME_WINDOW_EXPIRED' | 'UNKNOWN' | 'ADMINISTERED';
  export const NewCsStateEnum = {
    TOPREPARE: 'TO_PREPARE' as NewCsStateEnum,
    TOADMINISTER: 'TO_ADMINISTER' as NewCsStateEnum,
    NOTADMINISTEREDMISSED: 'NOT_ADMINISTERED_MISSED' as NewCsStateEnum,
    NOTADMINISTEREDREJECTED: 'NOT_ADMINISTERED_REJECTED' as NewCsStateEnum,
    NOTADMINISTEREDTIMEWINDOWEXPIRED: 'NOT_ADMINISTERED_TIME_WINDOW_EXPIRED' as NewCsStateEnum,
    UNKNOWN: 'UNKNOWN' as NewCsStateEnum,
    ADMINISTERED: 'ADMINISTERED' as NewCsStateEnum
  };
}
