/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DistrictDto } from './districtDto';
import { DoctorDto } from './doctorDto';
import { PatientDto } from './patientDto';


export interface MedicalRecordRequest { 
    closingReason?: string;
    district?: DistrictDto;
    /**
     * Doctor that is associated to medical record
     */
    doctor?: DoctorDto;
    id?: string;
    /**
     * Nosological must be constructed like this: AA (last two digits of the year) and D (domiciliary); Ex. 20D
     */
    nosological?: string;
    /**
     * Operator that is opening the medical record
     */
    operator?: DoctorDto;
    patient?: PatientDto;
    status?: MedicalRecordRequest.StatusEnum;
}
export namespace MedicalRecordRequest {
    export type StatusEnum = 'APERTA' | 'CHIUSA' | 'STORICIZZATA';
    export const StatusEnum = {
        APERTA: 'APERTA' as StatusEnum,
        CHIUSA: 'CHIUSA' as StatusEnum,
        STORICIZZATA: 'STORICIZZATA' as StatusEnum
    };
}
