/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ActivityDTO {
    activity?: string;
    activityDate?: string;
    activityInsert?: string;
    createDate?: string;
    createUserId?: number;
    deleteTime?: string;
    deleteUserUuid?: string;
    deleted?: boolean;
    fileUuid?: string;
    id?: number;
    manual?: number;
    medicalRecordUuid?: string;
    operatorName?: string;
    operatorSurname?: string;
    operatorUuid?: string;
    roleUuid?: string;
    therapy?: string;
    updateDate?: string;
    updateUserId?: number;
    userName?: string;
    userSurname?: string;
    uuid?: string;
}
