/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DistrictDto { 
    /**
     * The district asl
     */
    districtAsl: string;
    /**
     * The district code
     */
    districtCode: string;
    districtId?: string;
    /**
     * The district name
     */
    districtName: string;
}
