/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { PageParameterResponse } from '../model/pageParameterResponse';
import { ParameterDto } from '../model/parameterDto';
import { ParameterRequest } from '../model/parameterRequest';
import { ParameterResponse } from '../model/parameterResponse';
import { UpdateParameterResponse } from '../model/updateParameterResponse';
import { BASE_PATH } from '../variables';



@Injectable()
export class VitalParametersService {

  protected basePath = 'https://fidcare.kiranet.it/api/cpmbase';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * create
   *
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createUsingPOST(request: ParameterRequest, observe?: 'body', reportProgress?: boolean): Observable<ParameterDto>;
  public createUsingPOST(request: ParameterRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParameterDto>>;
  public createUsingPOST(request: ParameterRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParameterDto>>;
  public createUsingPOST(request: ParameterRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling createUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ParameterDto>(`${this.basePath}/vitalparameters`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * delete
   *
   * @param uuid uuid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteUsingDELETE(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateParameterResponse>;
  public deleteUsingDELETE(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateParameterResponse>>;
  public deleteUsingDELETE(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateParameterResponse>>;
  public deleteUsingDELETE(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling deleteUsingDELETE.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<UpdateParameterResponse>(`${this.basePath}/vitalparameters/delete/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the vital parameter
   *
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAll(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageParameterResponse>;
  public findAll(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageParameterResponse>>;
  public findAll(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageParameterResponse>>;
  public findAll(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageParameterResponse>(`${this.basePath}/vitalparameters`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get vital parameter by uuid
   *
   * @param uuid uuid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findByUuid(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ParameterResponse>;
  public findByUuid(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParameterResponse>>;
  public findByUuid(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParameterResponse>>;
  public findByUuid(uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling findByUuid.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<ParameterResponse>(`${this.basePath}/vitalparameters/${encodeURIComponent(String(uuid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * update
   *
   * @param request request
   * @param uuid uuid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateUsingPUT(request: ParameterRequest, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ParameterDto>;
  public updateUsingPUT(request: ParameterRequest, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParameterDto>>;
  public updateUsingPUT(request: ParameterRequest, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParameterDto>>;
  public updateUsingPUT(request: ParameterRequest, uuid: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling updateUsingPUT.');
    }

    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling updateUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<ParameterDto>(`${this.basePath}/vitalparameters/update/${encodeURIComponent(String(uuid))}`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
