/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { DoctorDto } from '../model/doctorDto';
import { DoctorRequest } from '../model/doctorRequest';
import { DoctorResponse } from '../model/doctorResponse';
import { PageDoctorResponse } from '../model/pageDoctorResponse';
import { BASE_PATH } from '../variables';



@Injectable()
export class DoctorService {

  protected basePath = 'https://fidcaresvil.kiranet.it';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Delete a Doctor
   *
   * @param doctorId doctorId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public _delete(doctorId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public _delete(doctorId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public _delete(doctorId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public _delete(doctorId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (doctorId === null || doctorId === undefined) {
      throw new Error('Required parameter doctorId was null or undefined when calling _delete.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/doctors/delete/${encodeURIComponent(String(doctorId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new Doctor
   *
   * @param doctorRequest doctorRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create1(doctorRequest: DoctorRequest, observe?: 'body', reportProgress?: boolean): Observable<DoctorDto>;
  public create1(doctorRequest: DoctorRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DoctorDto>>;
  public create1(doctorRequest: DoctorRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DoctorDto>>;
  public create1(doctorRequest: DoctorRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (doctorRequest === null || doctorRequest === undefined) {
      throw new Error('Required parameter doctorRequest was null or undefined when calling create1.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DoctorDto>(`${this.basePath}/doctors/saveDoctor`,
      doctorRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the doctor
   *
   * @param doctorEmail doctorEmail
   * @param doctorName doctorName
   * @param doctorSurname doctorSurname
   * @param page page
   * @param size size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAll(doctorEmail?: string, doctorName?: string, doctorSurname?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<PageDoctorResponse>;
  public findAll(doctorEmail?: string, doctorName?: string, doctorSurname?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDoctorResponse>>;
  public findAll(doctorEmail?: string, doctorName?: string, doctorSurname?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDoctorResponse>>;
  public findAll(doctorEmail?: string, doctorName?: string, doctorSurname?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {






    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (doctorEmail !== undefined && doctorEmail !== null) {
      queryParameters = queryParameters.set('doctorEmail', <any>doctorEmail);
    }
    if (doctorName !== undefined && doctorName !== null) {
      queryParameters = queryParameters.set('doctorName', <any>doctorName);
    }
    if (doctorSurname !== undefined && doctorSurname !== null) {
      queryParameters = queryParameters.set('doctorSurname', <any>doctorSurname);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDoctorResponse>(`${this.basePath}/doctors/getDoctorList`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the list of the doctor by surname
   *
   * @param surname surname
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBySurname(surname: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DoctorResponse>>;
  public findBySurname(surname: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DoctorResponse>>>;
  public findBySurname(surname: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DoctorResponse>>>;
  public findBySurname(surname: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (surname === null || surname === undefined) {
      throw new Error('Required parameter surname was null or undefined when calling findBySurname.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Array<DoctorResponse>>(`${this.basePath}/doctors/surname/${encodeURIComponent(String(surname))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update an existing Doctor
   *
   * @param doctorId doctorId
   * @param doctorRequest doctorRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public update1(doctorId: string, doctorRequest: DoctorRequest, observe?: 'body', reportProgress?: boolean): Observable<DoctorDto>;
  public update1(doctorId: string, doctorRequest: DoctorRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DoctorDto>>;
  public update1(doctorId: string, doctorRequest: DoctorRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DoctorDto>>;
  public update1(doctorId: string, doctorRequest: DoctorRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (doctorId === null || doctorId === undefined) {
      throw new Error('Required parameter doctorId was null or undefined when calling update1.');
    }

    if (doctorRequest === null || doctorRequest === undefined) {
      throw new Error('Required parameter doctorRequest was null or undefined when calling update1.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DoctorDto>(`${this.basePath}/doctors/${encodeURIComponent(String(doctorId))}`,
      doctorRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
