/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ComponentDto } from './componentDto';


export interface CreateTemplateRequest { 
    /**
     * The template's components list
     */
    componentDtoList: Array<ComponentDto>;
    /**
     * The template's name
     */
    name: string;
    /**
     * The template's Json schema
     */
    schema: string;
    /**
     * The template's user
     */
    user: string;
    /**
     * The template's uuid department
     */
    uuidDepartment: string;
    /**
     * The template's uuid Operative Unit
     */
    uuidOperativeUnit?: string;
    /**
     * The template type's uuid
     */
    uuidTemplateType: string;
}
