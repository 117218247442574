/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NationalityDTO } from './nationalityDTO';


export interface AslRegionDTO { 
    /**
     * The asl's code
     */
    code?: string;
    /**
     * The regions's creation date
     */
    createDate?: Date;
    /**
     * The create user's uuid
     */
    createUserUuid?: string;
    /**
     * The asl's descriprion
     */
    description?: string;
    /**
     * The nation to which the region belongs
     */
    nation?: NationalityDTO;
    /**
     * The region's update date
     */
    updateDate?: Date;
    /**
     * The update user's uuid
     */
    updateUserUuid?: string;
    /**
     * The region's uuid
     */
    uuid?: string;
}
