<h1 mat-dialog-title>{{modalTitle | translate}}</h1>
<mat-dialog-content>
	<div class="flex flex-wrap justify-between">
		<fidcare-qrcode-scanner [dataValidator]="dataValidator" (onDataRead)="onScanQRCodeSuccess($event)">
		</fidcare-qrcode-scanner>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-end">
	<button mat-raised-button class="mt-2" (click)="close()">
		{{'common.close' | translate}}
	</button>
</mat-dialog-actions>