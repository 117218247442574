/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ExperimentationRequest { 
    /**
     * The boolean active. Its indicates if the user is user controller or not
     */
    active?: boolean;
    /**
     * The boolean home care. Its indicates whether the kit is associated with the patient
     */
    homeCare?: boolean;
    /**
     * The experimentation's serial number
     */
    serialNumber?: string;
    /**
     * The category's uuid
     */
    uuidCategory: string;
    /**
     * The patient's uuid
     */
    uuidPatient: string;
}
