/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdministrationDto } from './administrationDto';
import { PatientDto } from './patientDto';


export interface AdministrationDetailsTOResponse { 
    administration?: AdministrationDto;
    drugCode?: string;
    drugName?: string;
    patient?: PatientDto;
    ptComponentNote?: string;
    therapyId?: string;
    therapyState?: AdministrationDetailsTOResponse.TherapyStateEnum;
}
export namespace AdministrationDetailsTOResponse {
    export type TherapyStateEnum = 'ACTIVE' | 'INTERRUPTED' | 'ONGOING' | 'SAVED' | 'TERMINATED';
    export const TherapyStateEnum = {
        ACTIVE: 'ACTIVE' as TherapyStateEnum,
        INTERRUPTED: 'INTERRUPTED' as TherapyStateEnum,
        ONGOING: 'ONGOING' as TherapyStateEnum,
        SAVED: 'SAVED' as TherapyStateEnum,
        TERMINATED: 'TERMINATED' as TherapyStateEnum
    };
}
