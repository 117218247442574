


<div style="width: 100%; height: 100%;">
	<div id="ol-map" #mapPreview style="width: 100%; height: 100%;">
	</div>
	<!-- <div class="map-preview-action-buttons">
		<button
			*ngFor="let action of actions"
			[enabled-by-permission]="action.enablePermission"
			(click)="invokeAction($event, action, reference)"
			[disabled]="!action.enableFunc(reference)"
			class="btn-clear {{ (!action.enableFunc(reference))?'element-disabled':'text-primary'}} cursor-pointer">
			<i class="{{action.icon}}"></i>
		</button>
	</div> -->
</div>
