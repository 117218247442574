/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasureUnitValue } from './measureUnitValue';


export interface ParameterRequest { 
    alternativeText?: string;
    decimalNumber?: number;
    description?: string;
    max?: number;
    measureUnit?: MeasureUnitValue;
    min?: number;
    name?: string;
    note?: string;
    type?: ParameterRequest.TypeEnum;
    uuid?: string;
}
export namespace ParameterRequest {
    export type TypeEnum = 'DECIMALE' | 'VALORI_AMMESSI' | 'STRINGA';
    export const TypeEnum = {
        DECIMALE: 'DECIMALE' as TypeEnum,
        VALORIAMMESSI: 'VALORI_AMMESSI' as TypeEnum,
        STRINGA: 'STRINGA' as TypeEnum
    };
}
