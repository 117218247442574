import { Transformation, TransformationSearchCriteria, CreateUpdateTemplate, TransformationDetails, UploadTemplate } from './../model/et.model';
import { Injectable } from '@angular/core';
import { HttpService } from 'infrastructure/fidcare/services/http.service';
import { Observable } from 'rxjs';
import { SearchResult } from 'infrastructure/fidcare/models/common.models';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TransformationService {
	constructor(private _http: HttpService) { }

	public list = (searchcriteria: TransformationSearchCriteria): Observable<SearchResult<Transformation>> => {

		let url = `${environment.services.api.etBasePath}/transformations`;
		return this._http.get(url, searchcriteria);
	}

	public create = (command: CreateUpdateTemplate): Observable<TransformationDetails> => {
		if(command.id){
			const url = `${environment.services.api.etBasePath}/transformations/${command.id}`;
			return this._http.put(url,command);
		}
		const url = `${environment.services.api.etBasePath}/transformations`;
		return this._http.post(url, command);
	}

	public details = (id: string): Observable<TransformationDetails> => {
		const url = `${environment.services.api.etBasePath}/transformations/${id}`;
		return this._http.get(url);
	}

	public delete = (id: string): Observable<any> => {
		const url = `${environment.services.api.etBasePath}/transformations/${id}`;
		return this._http.delete(url);
	}

	public downloadTemplate = (id: string): Observable<any> => {
		const url = `${environment.services.api.etBasePath}/transformations/${id}/template`;
		return this._http.get(url,null,{},"blob");
	}

	public uploadTemplate = (id: string, file:UploadTemplate): Observable<any> => {
		const url = `${environment.services.api.etBasePath}/transformations/${id}/template`;
		return this._http.post(url,file);
	}

	public transform= (id:string, entity:any):Observable<any>=>{
		const url = `${environment.services.api.etBasePath}/transformers/${id}`;
		return this._http.getFile(url,entity,{},false);

	}

}
