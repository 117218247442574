/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AssociationRequest { 
    /**
     * The experimentation's part number
     */
    partNumber: string;
    /**
     * The experimentation's serial number
     */
    serialNumber: string;
    /**
     * The patient's uuid
     */
    uuidPatient: string;
}
