import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { AlertConfigDto } from '../model/alertConfigDto';
import { ResponseEntity } from '../model/responseEntity';
import { BASE_PATH } from '../variables';

/**
 * measurementrule API
 * measurementrule API documentation
 *
 * OpenAPI spec version: 0.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class MedicalRecordAlertConfigResourceService {

    protected basePath = 'https://virtserver.swaggerhub.com/gant85/measurementrule-api/0.0.3';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createMedicalRecordAlertConfig
     *
     * @param measurementAlertConfig measurementAlertConfig
     * @param medicalRecordCode medicalRecordCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMedicalRecordAlertConfigUsingPOST(measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe?: 'body', reportProgress?: boolean): Observable<AlertConfigDto>;
    public createMedicalRecordAlertConfigUsingPOST(measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertConfigDto>>;
    public createMedicalRecordAlertConfigUsingPOST(measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertConfigDto>>;
    public createMedicalRecordAlertConfigUsingPOST(measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (measurementAlertConfig === null || measurementAlertConfig === undefined) {
            throw new Error('Required parameter measurementAlertConfig was null or undefined when calling createMedicalRecordAlertConfigUsingPOST.');
        }

        if (medicalRecordCode === null || medicalRecordCode === undefined) {
            throw new Error('Required parameter medicalRecordCode was null or undefined when calling createMedicalRecordAlertConfigUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AlertConfigDto>(`${this.basePath}/api/medicalrecords/${encodeURIComponent(String(medicalRecordCode))}/alertconfigs`,
            measurementAlertConfig,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteMedicalRecordAlertConfig
     *
     * @param id id
     * @param medicalRecordCode medicalRecordCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMedicalRecordAlertConfigUsingDELETE(id: string, medicalRecordCode: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public deleteMedicalRecordAlertConfigUsingDELETE(id: string, medicalRecordCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public deleteMedicalRecordAlertConfigUsingDELETE(id: string, medicalRecordCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public deleteMedicalRecordAlertConfigUsingDELETE(id: string, medicalRecordCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMedicalRecordAlertConfigUsingDELETE.');
        }

        if (medicalRecordCode === null || medicalRecordCode === undefined) {
            throw new Error('Required parameter medicalRecordCode was null or undefined when calling deleteMedicalRecordAlertConfigUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ResponseEntity>(`${this.basePath}/api/medicalrecords/${encodeURIComponent(String(medicalRecordCode))}/alertconfigs/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMedicalRecordAlertConfigs
     *
     * @param measurementCode measurementCode
     * @param medicalRecordCode medicalRecordCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMedicalRecordAlertConfigsUsingGET(measurementCode: string, medicalRecordCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AlertConfigDto>>;
    public getMedicalRecordAlertConfigsUsingGET(measurementCode: string, medicalRecordCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AlertConfigDto>>>;
    public getMedicalRecordAlertConfigsUsingGET(measurementCode: string, medicalRecordCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AlertConfigDto>>>;
    public getMedicalRecordAlertConfigsUsingGET(measurementCode: string, medicalRecordCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (measurementCode === null || measurementCode === undefined) {
            throw new Error('Required parameter measurementCode was null or undefined when calling getMedicalRecordAlertConfigsUsingGET.');
        }

        if (medicalRecordCode === null || medicalRecordCode === undefined) {
            throw new Error('Required parameter medicalRecordCode was null or undefined when calling getMedicalRecordAlertConfigsUsingGET.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (measurementCode !== undefined && measurementCode !== null) {
            queryParameters = queryParameters.set('measurementCode', <any>measurementCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AlertConfigDto>>(`${this.basePath}/api/medicalrecords/${encodeURIComponent(String(medicalRecordCode))}/alertconfigs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateMedicalRecordAlertConfig
     *
     * @param id id
     * @param measurementAlertConfig measurementAlertConfig
     * @param medicalRecordCode medicalRecordCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMedicalRecordAlertConfigUsingPUT(id: string, measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe?: 'body', reportProgress?: boolean): Observable<AlertConfigDto>;
    public updateMedicalRecordAlertConfigUsingPUT(id: string, measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertConfigDto>>;
    public updateMedicalRecordAlertConfigUsingPUT(id: string, measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertConfigDto>>;
    public updateMedicalRecordAlertConfigUsingPUT(id: string, measurementAlertConfig: AlertConfigDto, medicalRecordCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateMedicalRecordAlertConfigUsingPUT.');
        }

        if (measurementAlertConfig === null || measurementAlertConfig === undefined) {
            throw new Error('Required parameter measurementAlertConfig was null or undefined when calling updateMedicalRecordAlertConfigUsingPUT.');
        }

        if (medicalRecordCode === null || medicalRecordCode === undefined) {
            throw new Error('Required parameter medicalRecordCode was null or undefined when calling updateMedicalRecordAlertConfigUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AlertConfigDto>(`${this.basePath}/api/medicalrecords/${encodeURIComponent(String(medicalRecordCode))}/alertconfigs/${encodeURIComponent(String(id))}`,
            measurementAlertConfig,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
