/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AslRegionDTO } from './aslRegionDTO';


export interface NationalityDTO { 
    /**
     * Not used
     */
    aslRegion?: AslRegionDTO;
    /**
     * The nationality's cadastral code
     */
    cfis?: string;
    /**
     * The code associated to the nationality
     */
    code?: string;
    /**
     * The nationality's creation date
     */
    createDate?: Date;
    /**
     * The create user's uuid
     */
    createUserId?: string;
    /**
     * The nationality's name
     */
    description?: string;
    /**
     * The nationality's typology ENI/STP
     */
    typology?: string;
    /**
     * The nationality's update date
     */
    updateDate?: Date;
    /**
     * The update user's uuid
     */
    updateUserId?: string;
    /**
     * The nationality's uuid
     */
    uuid?: string;
}
