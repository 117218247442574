<button mat-icon-button (click)="goToNotification()" class="bg-card  mr-3">
	<ng-container *ngIf="unreadCount > 0">
		<span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
			<span
				class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
				{{unreadCount}}
			</span>
		</span>
	</ng-container>
	<mat-icon [svgIcon]="'heroicons_outline:bell'" class="text-primary"></mat-icon>
</button>

<!-- <button
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button> -->