/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ComponentResponseDto } from './componentResponseDto';


export interface MandatoryComponentsByTemplateTypeRespDto { 
    /**
     * The Required Components's list
     */
    requiredComponents: Array<ComponentResponseDto>;
    /**
     * The TemplateType's name
     */
    templateType: string;
    /**
     * The TemplateType's uuid
     */
    uuidTemplateType: string;
}
